import React, { useState }  from "react"
import { useStaticQuery, graphql } from "gatsby"
import axios from "axios";

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import * as Styles from "../styles/contact.module.scss"

const ContactPage = () => {
    const [serverState, setServerState] = useState({
        submitting: false,
        status: null
    });
    const handleServerResponse = (ok, msg, form) => {
        setServerState({
            submitting: false,
            status: { ok, msg }
        });
        if (ok) {
            form.reset();
        }
    };
    const handleOnSubmit = e => {
        e.preventDefault();
        const form = e.target;
        setServerState({ submitting: true });
        axios({
            method: "post",
            url: "https://getform.io/f/51cfed35-838a-40bb-927b-77a02fc965a4",
            data: new FormData(form)
        })
        .then(r => {
            handleServerResponse(true, "Thanks!", form);
        })
        .catch(r => {
            handleServerResponse(false, r.response.data.error, form);
        });
    };

    const DATA = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        divisionJa { support },
                        menuJa { contact }
                    }
                }
            }
        `
    )
    const CONTACT_JA = DATA.site.siteMetadata.menuJa.contact
    return (
        <Layout division='support'>
            <Seo title={`${CONTACT_JA} | ${DATA.site.siteMetadata.divisionJa.support}`} />
            <h1 className="support">{CONTACT_JA}</h1>
            <div id={Styles.support}>
                <form className={Styles.form_wrapper} onSubmit={handleOnSubmit}>
                    <div className={Styles.form_content}>
                        <div className={Styles.form_label}><label htmlFor="name">名前</label></div>
                        <div className={Styles.inputbox}>
                            <input type="text" name="name" id="name" placeholder="白玉　団子" className={Styles.input_text} required />
                        </div>
                    </div>
                    <div className={Styles.form_content}>
                        <div className={Styles.form_label}><label htmlFor="email">メールアドレス</label></div>
                        <div className={Styles.inputbox}>
                            <input type="email" name="email" id="email" placeholder="sample@office-shiratama.jp" className={Styles.input_text} required />
                        </div>
                    </div>
                    <div className={Styles.form_content}>
                        <div className={Styles.form_label}><label htmlFor="tel">電話番号</label></div>
                        <div className={Styles.inputbox}>
                            <input type="tel" name="tel" id="tel" placeholder="09001234567" className={Styles.input_text} required />
                        </div>
                    </div>
                    <div className={Styles.form_content}>
                        <div className={Styles.form_label}><label>ご希望の連絡手段</label></div>
                        <div className={Styles.inputbox}>
                            <label className="mr-6"><input type="radio" name="contact" value="mail" required />メール</label>
                            <label><input type="radio" name="contact" value="tel" />電話</label>
                        </div>
                    </div>
                    <div className={Styles.form_content}>
                        <div className={Styles.form_label}><label>相談種別</label></div>
                        <div className={Styles.inputbox}>
                            <select name="category" className={Styles.input_select} required>
                                <option value="">選択してください</option>
                                <option value="it">IT導入支援</option>
                                <option value="website">WEBサイト</option>
                                <option value="officework">事務代行</option>
                                <option value="support">事業主サポート</option>
                                <option value="other">その他</option>
                            </select>
                        </div>
                    </div>
                    <div className={Styles.form_content}>
                        <div className={`${Styles.form_label} sm:pb-28`}><label htmlFor="message">相談内容</label></div>
                        <div className={Styles.inputbox}>
                            <textarea name="message" id="message" rows="8" className={Styles.input_textarea} required />
                        </div>
                    </div>
                    <div className={Styles.button}>
                        <button type="submit">送信</button>
                    </div>
                </form>
            </div>
        </Layout>
    )
}
export default ContactPage